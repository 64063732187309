<template>
  <en-drawer :model-value="modelValue" :title="`${form.data.id ? '编辑' : '新建'}移库出库`" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')">
      <en-form-item label="出库仓库" prop="fromWarehouse.id">
        <select-maintain
          v-model="form.data.fromWarehouse"
          :ajax="{ action: 'GET /enocloud/common/warehouse', params: (params, value) => (params.payload = { name: value }) }"
          :props="{
            label: 'name',
            value: '',
            disabled: (option) => option.id === form.data.toWarehouse?.id
          }"
          value-key="id"
          :disabled="form.disabled"
          remote
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="入库仓库" prop="toWarehouse.id">
        <select-maintain
          v-model="form.data.toWarehouse"
          :ajax="{ action: 'GET /enocloud/common/warehouse', params: (params, value) => (params.payload = { name: value }) }"
          :props="{
            label: 'name',
            value: '',
            disabled: (option) => option.id === form.data.fromWarehouse?.id
          }"
          value-key="id"
          :disabled="form.disabled"
          remote
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="备注">
        <en-input v-model="form.data.comment" type="textarea" :disabled="form.disabled"></en-input>
      </en-form-item>
      <en-form-item label="物流单">
        <upload-maintain :model-value="form.data.imgUrls" multiple :limit="5" :disabled="form.disabled" dir="transferOut"></upload-maintain>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click" :disabled="form.disabled">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudInventoryDefinitions['StockTransferOutDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (value: number | undefined) => ['number', 'undefinded'].includes(typeof value)
  },

  watch: {
    modelValue: {
      async handler() {
        if (this.modelValue) {
          if (this.data?.id) {
            Object.assign(this.form.data, this.data)
            this.form.get()
          }
        } else {
          this.form.init()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/stock/transfer/out/:stockTransferOutId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/stock/transfer/out',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          update: {
            action: 'PUT /enocloud/stock/transfer/out',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        rules: {
          'fromWarehouse.id': { required: true, message: '请选择出库仓库' },
          'toWarehouse.id': { required: true, message: '请选择入库仓库' }
        },
        computed: {
          disabled() {
            return this.form.data.status?.code === 'A'
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            const res = await this.form[this.form.data.id ? 'update' : 'submit']()
            this.footer.cancel.click()
            this.emit('confirm', (res.data[0] as number | undefined) || this.form.data.id)
          }
        }
      }
    }
  }
})
</script>
